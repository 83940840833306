import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { useState } from 'react';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const Paper = makeShortcode("Paper");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "asking-for-help-and-support"
    }}>{`Asking for help and support`}</h2>
    <p>{`One important way to look after yourself is to seek and accept support from others. Research shows that having good social support is protective during stressful times.`}</p>
    <p>{`You can start by reaching out to supportive people you already know (e.g. friends, family, colleagues), or seek out new supports (e.g. online support groups, parenting groups or programs, religious groups, sporting groups, or health professionals).`}</p>
    {
      /* Need to save these somwhere? */
    }
    <Typography color="primary" mdxType="Typography">Name 3 people or organisations who you feel comfortable to turn to for help. </Typography>
    <Grid container justify="center" spacing={2} style={{
      marginTop: '24px',
      marginBottom: '24px'
    }} mdxType="Grid">
  <SaveTextField id="pandemicSeekHelp1" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  <SaveTextField id="pandemicSeekHelp2" variant="outlined" label="Insert text" mdxType="SaveTextField" />
  <SaveTextField id="pandemicSeekHelp3" variant="outlined" label="Insert text" mdxType="SaveTextField" />
    </Grid>
    <Grid container spacing={1} mdxType="Grid">
  <Grid item xs={2} align="center" mdxType="Grid">
    <ContactPhoneIcon color="primary" style={{
          fontSize: 40
        }} mdxType="ContactPhoneIcon" />
  </Grid>
  <Grid item xs={10} mdxType="Grid">
    Keep their contact details on hand for when you need to reach out. After doing this module, why don’t you send one of
    them a message or make a time to meet up online to strengthen your connection.
  </Grid>
    </Grid>
    <Paper elevation={0} style={{
      color: '#fff',
      backgroundColor: '#008c91',
      borderRadius: '20px'
    }} mdxType="Paper">
  If you need professional support, you can talk to your GP about seeing a local mental health professional. Check out
  the{' '}
  <GoTo to="/resources" mdxType="GoTo">
    <span style={{
          color: '#fff',
          textDecoration: 'underline'
        }}>Useful Resources</span>
  </GoTo>{' '}
  page for more information.
    </Paper>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  Many people’s financial or housing situation has been affected by the pandemic. If this is the case for your family,
  we encourage you to reach out for assistance from government or community services. Check out the{' '}
  <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page for suggestions of where to seek help.
  <br />
  <br />
  Be transparent with your teen that you intend to seek help. Tell them that there is no shame in seeking help when circumstances
  change. By doing this, you are being a good role model for your teen.
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      